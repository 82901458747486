import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27')
];

export const server_loads = [];

export const dictionary = {
		"/(app)": [4,[2]],
		"/(app)/about": [17,[2]],
		"/(app)/(auth)/admin/bookinger": [~5,[2]],
		"/(app)/(auth)/admin/bookinger/[bookingId]": [~6,[2]],
		"/(app)/(auth)/admin/soknader": [~7,[2]],
		"/(app)/(auth)/admin/soknader/[submissionId]": [~8,[2]],
		"/(app)/betingelser": [18,[2]],
		"/(app)/(auth)/bookinger": [~9,[2]],
		"/(app)/(auth)/bookinger/[bookingId]": [~10,[2]],
		"/(app)/booking/[doctorId]": [~19,[2]],
		"/(app)/cookiepolicy": [20,[2]],
		"/(aside)/kode": [~24,[3]],
		"/(aside)/login": [~25,[3]],
		"/(aside)/(auth)/oppdater-telefon": [~23,[3]],
		"/(app)/(auth)/pasienttimer": [~11,[2]],
		"/(app)/(auth)/pasienttimer/[bookingId]": [~12,[2]],
		"/(app)/personvern": [21,[2]],
		"/(app)/(auth)/profil": [~13,[2]],
		"/(app)/(auth)/profil/doktor": [~14,[2]],
		"/(app)/(auth)/profil/konsultasjonsdetaljer": [~15,[2]],
		"/(aside)/register": [~26,[3]],
		"/(app)/(auth)/soknad": [~16,[2]],
		"/(app)/spesialiseringer/[specialization]": [~22,[2]],
		"/(aside)/telefon": [~27,[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';